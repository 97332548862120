import { Ability, AbilityBuilder, createMongoAbility } from "@casl/ability";
import { useUserStore } from "~/store/auth";

export default defineNuxtPlugin(({ inject }) => {
  const userStore = useUserStore();
  const config = useRuntimeConfig();
  const rolesCookie = config.public.rolesCookieName;
  // const permissions = [];
  const userRoles = userStore.getItemWithExpiry(rolesCookie);

  // const userRoles = JSON.parse(localStorage.getItem(rolesCookie));

  const getUserPermissions = () => {
    let parsedRoles;
    try {
      parsedRoles = JSON.parse(userRoles);
    } catch (error) {
      console.error("Erreur lors de la conversion des rôles:", error);
      parsedRoles = [];
    }
    const roles = parsedRoles || [];
    const permissions = [];

    if (roles.length > 0) {
      roles.forEach((item) => {
        if (item.rules) {
          item.rules.forEach((rule) => {
            if (rule && rule.menu && rule.actions) {
              permissions.push({ action: "manage", subject: rule.menu.name });
              const actionPermissions = rule.actions.map((action) => ({
                action: "manage",
                subject: action.titre,
              }));
              permissions.push(...actionPermissions);
            }
          });
        }
      });
    }

    return { permissions, roles };
  };

  const userPermissions = getUserPermissions();

  // définir les capacités
  const { can, rules, cannot } = new AbilityBuilder(Ability);

  // const permissions = userPermissions.permissions;

  userPermissions.permissions.forEach((permission) => {
    console.log("permission =>", permission.subject);
    //If supervisor , can manage permission.object but cannot manage all
    if (permission.subject === "all") {
      can("manage", "all");
    } else if (permission.subject === "superviseur") {
      console.log("Here");
      can("manage", "all");
      cannot("manage", "user");
    } else {
      can("manage", permission.subject);
    }
  });

  // créer une nouvelle instance de Ability avec les règles définies
  const ability = createMongoAbility(rules);

  return {
    provide: {
      ability: ability,
    },
  };
});
